<template>
  <svg class="close-svg" width="9" height="9" viewBox="0 0 9 9" fill="none" xmlns="http://www.w3.org/2000/svg">
    <line x1="0.353553" y1="0.646447" x2="8.13173" y2="8.42462" stroke="#B8B7BC"/>
    <line x1="0.646447" y1="8.42477" x2="8.42462" y2="0.646592" stroke="#B8B7BC"/>
  </svg>

</template>

<script>
export default {
  name: 'Close'
}
</script>
