<template>
  <div class="g-header">
    <template v-if="pageName === 'index'">
      <div class="mob" v-if="deviceName && isMobAppLinkVisible">
        <div class="g-container">
          <div class="mob-app">
            <button @click="isMobAppLinkVisible = false">
              <Close />
            </button>
            <a
              target="_blank"
              :href="deviceName === 'android'
                ? androidLink
                : (
                  deviceName === 'lg-smart-tv'
                    ? lgLink
                    : iosLink
                )"
              class="mob-app-details">
              <div class="mob-img-text">
                <Vintera class="mob-img" />
                <div class="mob-texts">
                  <p class="mob-text">Vintera TV</p>
                  <p class="mob-count">Vintera TV</p>
                </div>
              </div>
              <div class="mob-right-texts">
                <p class="mob-right-text">{{ $t('open') }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="mob-menu-section">
        <div class="g-container">
          <div class="mob-menu-texts">
            <div class="menu-texts">
              <div class="header-locale header-locale--left" @click="toggleLocale">
                <En v-if="$i18n.locale === 'ru'" />
                <Ru v-else-if="$i18n.locale === 'en'" />
              </div>
              <div class="menu-text-icon">
                <a href="/" class="menu-text active" @click.prevent="onRedirect('/', { query: {} })">{{ $t('Главная') }}</a>
                <a href="/?all" class="menu-text" @click.prevent="onRedirect('/', { query: { all: null } })">{{ $t('Все каналы') }}</a>
              </div>
              <div class="header-search">
                <Search :value="searchValue" @input="onSearch" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- header -->

      <div class="header header--mb">
        <div class="g-container">
          <div class="header-item">
            <div class="qr-img">
              <a href="https://vintera.tv/" class="qr-img__back"><ArrowBack /></a>
              <a target="_blank" class="qr-img__qr" :href="androidLink" v-if="deviceName === 'android'">
                <qr />
              </a>
              <a target="_blank" class="qr-img__qr" :href="iosLink" v-else>
                <qr />
              </a>
            </div>
            <div class="header-text-icon">
              <a href="/?all" class="head-text head-text-all" @click.prevent="onRedirect('/', { query: { all: null } })">{{ $t('Полный список каналов') }}</a>
            </div>
            <div class="header-right">
              <Search class="header-search" :value="searchValue" @input="onSearch" />
              <div class="header-locale" @click="toggleLocale">
                <En v-if="$i18n.locale === 'ru'" />
                <Ru v-else-if="$i18n.locale === 'en'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="pageName === 'all'">
      <div class="mob" v-if="deviceName && isMobAppLinkVisible">
        <div class="g-container">
          <div class="mob-app">
            <button @click="isMobAppLinkVisible = false">
              <Close />
            </button>
            <a
              target="_blank"
              :href="deviceName === 'android'
                ? androidLink
                : (
                  deviceName === 'lg-smart-tv'
                    ? lgLink
                    : iosLink
                )"
              class="mob-app-details">
              <div class="mob-img-text">
                <Vintera class="mob-img" />
                <div class="mob-texts">
                  <p class="mob-text">Vintera TV</p>
                  <p class="mob-count">Vintera TV</p>
                </div>
              </div>
              <div class="mob-right-texts">
                <p class="mob-right-text">{{ $t('open') }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <div class="mob-menu-section">
        <div class="g-container">
          <div class="mob-menu-texts">
            <div class="menu-texts">
              <div class="header-locale header-locale--left" @click="toggleLocale">
                <En v-if="$i18n.locale === 'ru'" />
                <Ru v-else-if="$i18n.locale === 'en'" />
              </div>
              <div class="header-text-icon">
                <a href="/" class="head-text head-text-all" @click.prevent="onRedirect('/', { query: {} })">{{ $t('Главная') }}</a>
              </div>
              <div class="header-search">
                <Search :value="searchValue" @input="onSearch" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- header -->

      <div class="header">
        <div class="g-container">
          <div class="header-item">
            <div class="qr-img">
              <button @click="onGoBack"><ArrowBack /></button>
              <a class="qr-img__qr" target="_blank" :href="androidLink" v-if="deviceName === 'android'">
                <qr />
              </a>
              <a class="qr-img__qr" target="_blank" :href="iosLink" v-else>
                <qr />
              </a>
            </div>
            <div class="head-text-icon">
              <a href="/" @click.prevent="onRedirect('/', { query: {} })" class="head-text">{{ $t('Главная') }}</a>
              <a href="/?all" class="head-text active" @click.prevent="onRedirect('/', { query: { all: null } })">{{ $t('Все каналы') }}</a>
            </div>
            <div class="header-right">
              <Search class="header-search" :value="searchValue" @input="onSearch" />
              <div class="header-locale" @click="toggleLocale">
                <En v-if="$i18n.locale === 'ru'" />
                <Ru v-else-if="$i18n.locale === 'en'" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template v-else-if="pageName === 'channel'">

      <!-- mob responsive -->

      <div class="mob" v-if="deviceName && isMobAppLinkVisible">
        <div class="g-container">
          <div class="mob-app">
            <button @click="isMobAppLinkVisible = false">
              <Close />
            </button>
            <a :href="deviceName === 'android' ? androidLink : iosLink" class="mob-app-details" target="_blank">
              <div class="mob-img-text">
                <Vintera class="mob-img" />
                <div class="mob-texts">
                  <p class="mob-text">Vintera TV</p>
                  <p class="mob-count">Vintera TV</p>
                </div>
              </div>
              <div class="mob-right-texts">
                <p class="mob-right-text">{{ $t('open') }}</p>
              </div>
            </a>
          </div>
        </div>
      </div>

      <!-- header -->

      <div class="header">
        <div class="g-container">
          <div class="header-item">
            <div class="qr-img">
              <a class="qr-img__back" href="/" @click.prevent="onRedirect('/', { query: {} })">
                <ArrowBack />
              </a>
              <a href="/" @click.prevent="onRedirect('/', { query: {} })"><p class="head-texts">{{ $t('Главная') }}</p></a>
            </div>
            <div class="head-text-icon">
              <a
                :href="androidLink"
                target="_blank"
              >
                <img src="img/googleplay.png" class="googleplay" alt="" />
              </a>
              <a :href="iosLink" target="_blank">
                <img src="img/appstore.png" class="appstore" alt="" />
              </a>
            </div>
            <div class="qr-img qr-img--right">
              <a class="qr-img__qr" target="_blank" :href="androidLink" v-if="deviceName === 'android'">
                <qr />
              </a>
              <a class="qr-img__qr" target="_blank" :href="iosLink" v-else>
                <qr />
              </a>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import ArrowBack from '@/components/icons/ArrowBack'
import Qr from '@/components/icons/QR'
import deviceName from '@/utils/deviceName'
import Close from '@/components/icons/Close'
import Vintera from '@/components/icons/Vintera'
export default {
  name: 'Header',

  components: {
    Vintera,
    Close,
    Qr,
    ArrowBack,
    En: () => import('@/components/icons/En'),
    Ru: () => import('@/components/icons/Ru'),
    Search: () => import('@/components/Search')
  },

  props: {
    pageName: {
      type: String,
      required: true
    },
    searchValue: {
      type: String,
      required: true
    },
    iosLink: {
      type: String,
      required: true
    },
    androidLink: {
      type: String,
      required: true
    },
    lgLink: {
      type: String,
      required: true
    }
  },

  data () {
    return {
      deviceName: deviceName(),
      isMobAppLinkVisible: true
    }
  },

  methods: {
    toggleLocale () {
      this.$i18n.locale = this.$i18n.locale === 'en' ? 'ru' : 'en'
    },

    onRedirect (path, opts) {
      this.$emit('redirect', { path, opts })
    },

    onGoBack () {
      try {
        this.$router.push('/')
      } catch {}
    },

    onSearch (v) {
      this.$emit('search', v)
    }
  }
}
</script>
